export const appConstants = {
  ROLES: {
    SUPER: 'SUPER',
    VIEWER: 'VIEWER',
    OWNER: 'OWNER',
  },
  ROUTES: {
    SITES_PAGES: '/sites/site-manager',
  },
};
